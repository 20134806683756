:root {
    --btn: rgba(255, 255, 255, 0.08);
    --btn2: rgba(255, 255, 255, 0.02);
    --boxShadow: 0 0 20px 10px rgba(255, 255, 255, 0.10);
    --boxShadow2: 0 0 20px 2px rgba(255, 255, 255, 0.04);
  }
body {
    /* background: #020880; */
    background: #041cad;
    font-family:'PPNeueMontreal-Bold' !important;
    margin: 0;
}
.App {
    font-family: 'PPNeueMontreal-Bold' !important;
    position: relative;

}
* {
    font-family: 'PPNeueMontreal-Bold' !important;
}
#content {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    z-index: 10;   
}



#step-2 {
    padding-top: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.background {
    width: 100%;
    height: auto;
}

.background#desktop {
    display: block;
}
.background#mobile {
    display: none;
}


@media (min-width: 701px) {
    #step-1{
        padding-top: 30%;
    } 
}
@media (max-width: 700px) {
    #content {
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .background#desktop {
        display: none;
    }
    .background#mobile {
        display: block;
    }
    #container {
        max-width: calc(100% - 32px);
        padding: 0 16px;
    }
}
@media (max-width: 400px) {
    #step-2 {
        padding-top: 15px !important;
    } 
}
@media (max-width: 360px) {
    #step-2 button {
        margin-top: 0 !important;
    }
}


@keyframes glowing {
    0% {
      background-color: var(--btn);
      box-shadow: var(--boxShadow);
    }
    50% {
      background-color: var(--btn2);
      box-shadow:  var(--boxShadow2);
    }
    100% {
      background-color: var(--btn);
      box-shadow: var(--boxShadow);
    }
  }
  .animated-btn {
    animation: glowing 2000ms infinite;
    border-radius: 50px;
    padding: 0 50px !important;
  }
  .button, button p, button svg  {
    color: #000;
  }